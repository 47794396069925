
import {useState, useLayoutEffect} from 'react'


export default function useWindowSize() {
  
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();

      
      
    }, []);
    return size;
  }