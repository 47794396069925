import React, { useState } from 'react';
import './styles/User-login-style.css'
import LoginMobile from './Components/LoginMobile'


function LoginTest () {


return (
    <div className="pupil-mobile-static-image ">
    <LoginMobile/>
    </div>
    )
}
export default LoginTest;